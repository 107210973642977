<template>
  <NuxtLayout name="default">
    <template #subnavigation>
      <h2 class="font-medium text-gray-900">
        Account settings
      </h2>
      <UiSubnavigation
        :items="[
          { to: '/account', icon: 'settings-01', label: 'General' },
          { to: '/account/company', icon: 'building-07', label: 'Company' },
          { to: '/account/billing', icon: 'currency-dollar', label: 'Billing' },
        ]"
        class="mt-4"
      />
    </template>
    <template #breadcrumbs>
      <UiBreadcrumbs
        :items="[
          { to: '/', icon: 'home-line' },
          { to: '/account', label: 'Account settings' },
          { to: path, label: title, current: true },
        ]"
      />
    </template>
    <h1 class="text-2xl font-semibold text-gray-900">
      Account Settings
    </h1>
    <p class="text-base text-gray-600">
      Manage your account & company information here
    </p>
    <UiDivider class="my-6" />
    <slot />
  </NuxtLayout>
</template>

<script setup lang="ts">
defineProps<{
  title: string
}>()

const { path } = useRoute()
</script>
